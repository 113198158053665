import axios from "axios";
import Vue from 'vue'
import Vuex from 'vuex'
import store from '@/store'

axios.defaults.baseURL = `${process.env.VUE_APP_BACKEND_HOST}/`;
// axios.defaults.withCredentials = true;
axios.defaults.headers.common.Authorization = localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null;




export default {
  install() {
    Vue.prototype.$axios = axios
    Vuex.Store.prototype.$axios = axios
    function request(request) {
      const axiosSource = axios.CancelToken.source();
      request.cancelToken = axiosSource.token;
      store.dispatch("addRequest", {
        ...request,
        cancel: axiosSource.cancel,
      });
      return request;
    }

    function requestError() {
      store.commit("requestFailed");
    }
    axios.interceptors.request.use(request, requestError);
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (
          error &&
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry &&
          !error.config.url.includes("refresh")
        ) {
          localStorage.removeItem("token");
          delete axios.defaults.headers.common.Authorization;
          if (localStorage.getItem("refresh")) {
            originalRequest._retry = true;
            let fd = new FormData();
            fd.append("refresh", localStorage.getItem("refresh"));
            return await fetch(
              `${process.env.VUE_APP_BACKEND_HOST}api/v1/refresh/`,
              {
                method: "POST",
                body: fd,
              }
            ).then(async (res) => {
              if (res.status === 200) {
                let result = await res.json();
                localStorage.setItem("token", result.access);
                localStorage.setItem("refresh", result.refresh);
                axios.defaults.headers.common.Authorization =
                  "Bearer " + result.access;
                originalRequest.headers.Authorization =
                  "Bearer " + result.access;
                await store.dispatch('getSession')
                return Promise.resolve(
                  await axios.request(originalRequest)
                );

              } else {
                delete axios.defaults.headers.common.Authorization;
                delete originalRequest.headers.Authorization;
                localStorage.removeItem("token");
                localStorage.removeItem("refresh");
                // window.open('https://oauth.yandex.ru/authorize?response_type=token&client_id=0a042cf96e6d4f91a6423aada2e164da', '_self')
                return Promise.reject(error);
              }
            })
          } else {
            delete axios.defaults.headers.common.Authorization;
            delete originalRequest.headers.Authorization;
            return Promise.reject(error);
            // window.open('https://oauth.yandex.ru/authorize?response_type=token&client_id=0a042cf96e6d4f91a6423aada2e164da', '_self')
          }
        } else if (
          error &&
          error.response &&
          error.response.status === 401 &&
          originalRequest._retry
        ) {
          window.open(`https://oauth.yandex.ru/authorize?response_type=token&client_id=${process.env.VUE_APP_YANDEX_CLIENT_ID}`, '_self')
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );
  }
};